import { useModal } from "~/utils";
import { ApplyAsGroupModal, ApplyModal } from "./modals";
import { IGetOpportunityResponse } from "~/types";
import { ReactComponent as UsersIcon } from "~/assets/icons/users.svg";

export default function MobileApplyBar({
  opportunity,
}: {
  opportunity: IGetOpportunityResponse | undefined;
}) {
  if (opportunity === undefined) {
    return null;
  }
  const eoiApplyModal = useModal();
  const eoiApplyAsGroupModal = useModal();

  return (
    <>
      <div className="d-sm-none sticky-bottom w-100 bg-light mt-4">
        <div className="d-flex flex-wrap justify-content-between align-items-center py-3 mx-n2 px-2 bg-light">
          <button
            type="button"
            onClick={() => eoiApplyModal.onShow()}
            className="flex-shrink-0 btn btn-success btn-lg shadow-sm flex-grow-1 fs-6"
            style={{
              marginRight: opportunity.isSuitableForGroup ? "1.25rem" : "0rem", // Adjust the space between buttons
            }}
          >
            Apply now
          </button>

          {opportunity.isSuitableForGroup && (
            <button
              type="button"
              onClick={() => eoiApplyAsGroupModal.onShow()}
              className="flex-shrink-0 btn btn-success d-flex btn-lg align-items-center gap-2 shadow-sm flex-grow-1 fs-6 mx-auto"
            >
              <UsersIcon
                aria-hidden
                width={24}
                height={24}
                className="d-flex flex-shrink-0"
              />
              Apply as a group
            </button>
          )}
        </div>
      </div>

      {eoiApplyModal.show && (
        <ApplyModal
          {...eoiApplyModal}
          oppName={opportunity.name}
          oppId={opportunity.id}
          orgName={opportunity.organisationName}
        />
      )}
      {eoiApplyAsGroupModal.show && (
        <ApplyAsGroupModal
          {...eoiApplyAsGroupModal}
          oppName={opportunity.name}
          oppId={opportunity.id}
          orgName={opportunity.organisationName}
        />
      )}
    </>
  );
}

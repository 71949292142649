import { RouteComponentProps } from "@reach/router";
import { ApiProvider, QueryProvider, createApi } from "~/api";
import {
  ErrorState,
  OpportunityContactUsBanner,
  OpportunityDataAside,
  OpportunityDataHeader,
  OpportunityDataMain,
} from "~/components";
import MobileApplyBar from "~/components/MobileApplyBar";
import { Layout, Meta, Section } from "~/ui";
import {
  getAreasOfInterestCards,
  useAreasOfInterest,
  useOpportunity,
} from "~/utils";
import useHasMounted from "~/utils/useHasMounted";

export const Opportunity = ({ location }: RouteComponentProps) => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  const pathname = location?.pathname || "";
  const parts = pathname.split("/");
  const id = parts[parts.length - 1];

  const api = createApi(process.env.GATSBY_PUBLIC_ID || "");

  return (
    <ApiProvider api={api}>
      <QueryProvider>
        <Layout>
          <OpportunityData id={Number(id)} />
          <OpportunityContactUsBanner />
        </Layout>
      </QueryProvider>
    </ApiProvider>
  );
};

export default Opportunity;

const OpportunityData = ({ id }: { id: number }) => {
  const { isLoading, isError, data: opportunity } = useOpportunity(id);
  const { data: AOIList } = useAreasOfInterest();
  const areasOfInterest = getAreasOfInterestCards(AOIList || []);

  if (isError && !isLoading) {
    return <ErrorState />;
  }

  return (
    <>
      <OpportunityDataHeader
        isLoading={isLoading}
        areasOfInterest={areasOfInterest}
        opportunity={opportunity}
      />
      <Section
        bgColor="offWhite"
        baseBgColorTop="creamGradientEnd"
        roundedCorners={{ "top-right": true }}
      >
        <div className="row mb-4 mb-lg-0">
          <div className="col-12 col-lg-8 pe-lg-5 mb-3 mb-lg-0">
            <OpportunityDataMain
              isLoading={isLoading}
              opportunity={opportunity}
            />
          </div>
          <div className="col-12 col-lg-4">
            <OpportunityDataAside
              isLoading={isLoading}
              opportunity={opportunity}
            />
          </div>
          <MobileApplyBar opportunity={opportunity} />
        </div>
      </Section>
    </>
  );
};

export const Head = () => {
  return <Meta title="Opportunity details" />;
};
